import Section from "../../../section/Section";
import Image from "../../../Image";
import SocialEnterpriseLogo from "@images/v2/social-enterprise-logo-only.svg";

export default function AffordableSection({ title, name, jobTitle, mainImage, logo }) {
	return (
		<Section className="affordable-section mb-6 mt-7 mb-9 mt-md-14 mb-md-18 pt-11 pb-11 pt-md-12 pb-md-9 d-flex flex-column align-items-center bg-light-green border border-dark text-center">
			<p className="m-0 pb-2 pb-md-8 font-size-lg text-underline">Affordable</p>
			<h2 className="pb-3">Career Coaching for all</h2>

			<p className="m-0 pb-4 font-size-xl" style={{ maxWidth: 587 }}>
				We believe in a future where every person in every workplace has access to a career coach.
			</p>
			<p className="m-0 pb-4 font-size-xl" style={{ maxWidth: 587 }}>
				Our smart tech and commitment to reinvest 51% of annual profits into initiatives that help disadvantaged people
				makes this happen.
			</p>
			<p className="m-0 pb-9 font-size-xl" style={{ maxWidth: 587 }}>
				This means we will always offer the most affordable support in the market.
			</p>
			<p className="m-0 pb-2 font-size-xxxs">Proud to be a Social Enterprise</p>
			<div className="d-flex justify-content-center align-items-center">
				<Image src={SocialEnterpriseLogo} alt="Social Enterprise" width={80} height={80} className="" />
			</div>
		</Section>
	);
}
